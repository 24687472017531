<template>
  <div class="lineitems">
    <div class="lineitems-spinner" v-if="loading">
      <b-spinner small></b-spinner>
    </div>
    <div v-else :key="remountLineItems">
      <section
        class="lineitem-container"
        :id="'line-item' + index"
        v-for="(option, index) in selectedLineItems"
        :key="index"
      >
        <div
          class="my-line-items"
          v-if="showFeatureSearchItemAndAddMoreItems"
          :key="keySuggestions"
        >
          <RCInput
            label="Commodities catalogue"
            type="text"
            v-model="option.lineItemName"
            :suggestions="lineItemsDescriptions"
            :forceSelection="true"
            :floatLabel="true"
            class="my-line-items-selector"
            @blur="dataHandlingLineItem(index, option.lineItemName)"
          >
          </RCInput>
        </div>
        <b-overlay
          :show="option.isLoading"
          spinner-small
          rounded="sm"
          class="d-flex flex-column align-items-center"
          style="margin: 7px auto"
        >
          <header v-if="showFeatureSearchItemAndAddMoreItems" class="lineitem-container__title">
            <span>{{ $t("allOptions.line") }} {{ index + 1 }}</span>
            <button
              class="remove-button"
              v-if="showDeleteButton && $route.name.toLowerCase() !== 'loadsdetails'"
              type="button"
              @click="deleteLine(index)"
            >
              <i class="ion ion-close-circle-outline remove-button__icon" title="Remove Line"></i>
            </button>
          </header>
          <div class="lineitem-row">
            <div class="hucount lineitem-inputs">
              <RCInput
                :label="$t('allOptions.handlingUnitsCount')"
                :class="{ 'missing-field': error[index]?.handling_unit_count === true }"
                :positiveNumbers="true"
                :floatLabel="true"
                :requiredField="true"
                type="number"
                v-model="option.handling_unit_count"
                @blur="
                  dataHandling(index, 'handling_unit_count');
                  if (option.handling_unit_count < 0) option.handling_unit_count = 0;
                "
              />
              <RCInput
                class="hucount__dropdown"
                :class="{ 'missing-field': error[index]?.handling_units === true }"
                :floatLabel="true"
                :label="$t('allOptions.handlingUnits')"
                :type="'text'"
                :requiredField="true"
                :forceSelection="true"
                :suggestions="handlingUnitsList"
                v-model="option.handling_units"
                :minlength="inputLimits.handling_units.min"
                :maxlength="inputLimits.handling_units.max"
                @input="dataHandling(index, 'handling_units')"
              />
            </div>
            <ul class="weight lineitem-inputs">
              <li class="weight__input" :class="{ 'missing-field': error[index]?.weight === true }">
                <PopupComponent
                  class="weight__tooltip"
                  :content="weightTooltip"
                  :target="'weight-tooltip' + index"
                />
                <RCInput
                  :floatLabel="true"
                  :label="$t('allOptions.weight')"
                  :positiveNumbers="true"
                  :requiredField="true"
                  type="number"
                  v-model="option.user_weight"
                  @blur="
                    dataHandling(index, 'weight');
                    if (option.user_weight < 0) option.user_weight = 0;
                  "
                />
              </li>
              <li class="weight__dropdown">
                <RCSelect
                  :id="index"
                  :suggestions="picklist.weight_units"
                  v-model="option.user_weight_units"
                  @change="dataHandling(index, 'weight_units')"
                />
              </li>
            </ul>
            <ul class="dimensions lineitem-inputs">
              <li
                class="dimensions__input"
                :class="{ 'missing-field': error[index]?.length === true }"
              >
                <RCInput
                  :label="$t('allOptions.length')"
                  :floatLabel="true"
                  :positiveNumbers="true"
                  :requiredField="true"
                  type="number"
                  v-model="option.user_length"
                  @blur="
                    dataHandling(index, 'length');
                    if (option.user_length < 0) option.user_length = 0;
                  "
                />
              </li>
              <li
                class="dimensions__input"
                :class="{ 'missing-field': error[index]?.width === true }"
              >
                <RCInput
                  :label="$t('allOptions.width')"
                  :floatLabel="true"
                  :positiveNumbers="true"
                  :requiredField="true"
                  type="number"
                  v-model="option.user_width"
                  @blur="
                    dataHandling(index, 'width');
                    if (option.user_width < 0) option.user_width = 0;
                  "
                />
              </li>
              <li
                class="dimensions__input"
                :class="{ 'missing-field': error[index]?.height === true }"
              >
                <RCInput
                  :label="$t('allOptions.height')"
                  :floatLabel="true"
                  :positiveNumbers="true"
                  :requiredField="true"
                  type="number"
                  v-model="option.user_height"
                  @blur="
                    dataHandling(index, 'height');
                    if (option.user_height < 0) option.user_height = 0;
                  "
                />
              </li>
              <li class="dimensions__dropdown">
                <RCSelect
                  :id="index"
                  :suggestions="picklist.dimension_units"
                  :label="$t('allOptions.dimensionsUnits')"
                  v-model="option.user_dimension_units"
                  @change="dataHandling(index, 'dimension_units')"
                />
              </li>
            </ul>
            <div class="nmfc lineitem-inputs">
              <RCSelect
                v-loading="nmfcLoading"
                class="nmfc__dropdown"
                :class="{ 'missing-field': error[index]?.nmfc_class === true }"
                :id="index"
                :suggestions="picklist.nmfc_class"
                :label="$t('allOptions.nmfc')"
                :floatLabel="true"
                :requiredField="true"
                v-model="option.nmfc_class"
                @change="dataHandling(index, 'nmfc_class')"
                :key="key"
              />
            </div>
            <div class="commodity lineitem-inputs">
              <RCInput
                class="commodity__input"
                :class="{ 'missing-field': error[index]?.description === true }"
                :floatLabel="true"
                :label="$t('allOptions.commodity')"
                :type="'text'"
                :requiredField="true"
                v-model="option.description"
                @input="dataHandling(index, 'description')"
                :suggestions="commoditySuggestions"
              />
              <span class="commodity-excluded"
                >List of <u @click="openModalExcluded()"> excluded commodities</u></span
              >
            </div>
          </div>
        </b-overlay>
        <el-alert
          v-if="changeNmfc[index]"
          type="warning"
          show-icon
        >
          <template #title>
            The estimated class based on the dimensions differs from the class you have selected.
            <a
              class="alert-changes-nmfc"
              @click="changeToNmfcCalculated(index)"
            >
              Click to use the estimated class
              ({{ oldValueNmfcClass[index] }})
            </a>
          </template>
        </el-alert>
      </section>
    </div>
    <CustomModal v-model="excludedModal" modalTitle="Excluded Commodities" centered size="lg">
      <template #modal-content>
        <ModalExcludedCommodities :quoteMode="quoteMode" />
      </template>
    </CustomModal>
    <div v-if="showFeatureSearchItemAndAddMoreItems" class="lineitems__content-buttons">
      <button class="button-add" @click="addLine()">
        <b-icon icon="plus" font-scale="2.5"></b-icon>
        {{ $t("allOptions.addLine") }}
      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import { convertLengthMeasurements } from "@/utils/measurementConverter";
import CustomModal from "@/components/CustomModal.vue";
import RCInput from "../../../../components/RCComponents/CustomRCInput.vue";
import RCSelect from "../../../../components/RCComponents/CustomRCSelect.vue";
import LineItemSchema from "../../../../schemas/LineItemSchema";
import LineItems from "../../../../Extend/LineItems";
import PopupComponent from "../../../../components/PopupComponent.vue";
import ModalExcludedCommodities from "./components/ModalExcludedCommodities.vue";

export default {
  /* eslint-disable operator-linebreak */
  /* eslint-disable max-len */
  name: "Lineitems",
  mixins: [LineItems],
  props: {
    lineItems: Array,
    quoteMode: String,
    showFeatureSearchItemAndAddMoreItems: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    RCInput,
    RCSelect,
    PopupComponent,
    CustomModal,
    ModalExcludedCommodities,
  },
  data() {
    return {
      initialNMFCList: [],
      nmfcLoading: false,
      weightTooltip:
        "Enter the total weight per line, which should be the sum of the weights of all units on each line. Double-check the 'Total Weight' field before proceeding.",
      limitsLineItemsError: [],
      excludedModal: false,
      changeNmfc: [],
      oldValueNmfcClass: [],
      lineItemsData: [],
      lineItemName: "",
      lineItemsDescriptions: [],
      keySuggestions: 0,
      inputLimits: {
        handling_units: {
          min: 1,
          max: 15,
        },
      },
    };
  },
  async created() {
    await this.getMyLineItems();
    this.buildInitialNMFCList();
    if (this.$route.params.loadId) {
      this.calculateNMFC();
    }
  },
  methods: {
    async getMyLineItems() {
      const lineItemsGetAll = await this.$store.dispatch(
        "accountCommodity/getAllAccountComodityInfo"
      );
      this.lineItemsData = lineItemsGetAll;
      this.lineItemsDescriptions = this.lineItemsData.map((item) => item.description);
      this.keySuggestions += 1;
    },
    dataHandlingLineItem(index, nameOptionItem) {
      if (nameOptionItem) {
        const selectedOptionLineItem = this.lineItemsData.find(
          (item) => item.description === nameOptionItem
        );
        this.assignSeletectedItemInLineItems(index, nameOptionItem, selectedOptionLineItem);
        this.dataHandling(index, "handling_unit_count");
        this.remountLineItems += 1;
      }
    },
    assignSeletectedItemInLineItems(index, nameOptionItem, selectedOptionItem) {
      if (selectedOptionItem) {
        const { id, ...updatedItem } = selectedOptionItem;
        this.selectedLineItems[index] = {
          ...updatedItem,
          lineItemName: nameOptionItem,
          pickup_stop: "pickup",
          delivery_stop: "delivery",
          name: `Item ${index + 1}`,
        };
      }
    },
    openModalExcluded() {
      this.excludedModal = true;
    },
    // nmfc functions
    buildInitialNMFCList() {
      this.selectedLineItems.forEach((el, index) => {
        this.initialNMFCList.push({ nmfc_class: el.nmfc_class });
        this.oldValueNmfcClass[index] = el.nmfc_class;
      });
    },
    calculateNMFC() {
      this.selectedLineItems.forEach((item, index) => {
        if (parseInt(item.nmfc_class, 10) === 0) {
          this.standardizeNmfcClass(index);
        }
      });
    },
    async standardizeNmfcClass(index) {
      if (this.$options.filters.checkPermission("get:standardize-nmfc")) {
        this.nmfcLoading = true;
        const properties = [
          "weight",
          "weight_units",
          "handling_unit_count",
          "handling_units",
          "length",
          "height",
          "width",
          "dimension_units",
        ];
        const allDataIsFilled = this.validateFilledDataToStandardizeNmfc(
          properties,
          this.selectedLineItems[index]
        );
        if (allDataIsFilled) {
          const params = {
            weight: this.selectedLineItems[index].weight,
            weight_units: this.selectedLineItems[index].weight_units,
            handling_unit_count: this.selectedLineItems[index].handling_unit_count,
            handling_units: this.selectedLineItems[index].handling_units,
            length: this.selectedLineItems[index].length,
            height: this.selectedLineItems[index].height,
            width: this.selectedLineItems[index].width,
            dimension_units: this.selectedLineItems[index].dimension_units,
          };
          this.$store.commit("load/setUpdatingRequestInProcess", true);
          const response = await this.$store.dispatch("standardizers/getNmfcStandardizers", {
            params,
          });
          this.selectedLineItems[index].nmfc_class = response.nmfc;
          this.initialNMFCList[index].nmfc_class = response.nmfc;
          this.key += 1;
          this.$store.commit("load/setUpdatingRequestInProcess", false);
        }
        this.nmfcLoading = false;
      }
    },
    // This function validates the needed properties to execute the nfmc standarizerd
    validateFilledDataToStandardizeNmfc(properties, payload) {
      let allDataIsFilled = true;
      properties.forEach((key) => {
        if (!payload[key]) {
          allDataIsFilled = false;
        }
      });
      return allDataIsFilled;
    },
    // dimensions functions
    dimensionConverter(index) {
      this.selectedLineItems[index].length = parseFloat(
        convertLengthMeasurements(
          this.selectedLineItems[index].user_dimension_units,
          this.selectedLineItems[index].user_length
        ),
        10
      ).toFixed(2);
      this.selectedLineItems[index].width = parseFloat(
        convertLengthMeasurements(
          this.selectedLineItems[index].user_dimension_units,
          this.selectedLineItems[index].user_width
        ),
        10
      ).toFixed(2);
      this.selectedLineItems[index].height = parseFloat(
        convertLengthMeasurements(
          this.selectedLineItems[index].user_dimension_units,
          this.selectedLineItems[index].user_height
        ),
        10
      ).toFixed(2);
    },
    // Add line to FLT line items with the nmfc_class.
    addLine() {
      const newLine = {
        name: `Item ${this.selectedLineItems.length + 1}`,
        handling_units: "Pallets",
        dimension_units: "in",
        weight_units: "lbs",
        nmfc_class: null,
        height: "",
        length: "",
        width: "",
        weight: "",
        // New fields:
        user_height: "",
        user_length: "",
        user_width: "",
        user_weight: "",
        user_dimension_units: "in",
        user_weight_units: "lbs",
        pickup_stop: "pickup",
        delivery_stop: "delivery",
        linear_feet: 0.1,
        description: "",
        handling_unit_count: "",
        hazardous_materials: false,
        isNewLineItem: true,
        quoteMode: this.quoteMode,
        lineItemName: "",
      };
      if (this.$route.params.loadId) {
        this.updateLineItemsInIndexedDB();
      }
      this.selectedLineItems.push(newLine);
      this.initialNMFCList.push({ nmfc_class: "" });
      // Adds a new line item deep copy in the vuex state.
      this.saveSelectedLineItemInVuex();
    },
    async dataHandling(index, key) {
      const { loadId } = this.$route.params;
      const dimensions = ["length", "width", "height", "dimension_units"];
      const notStandardizerValues = ["description", "selectedClass", "nmfc_class"];
      if (key.match("weight")) {
        this.weightConverter(index);
      } else if (dimensions.includes(key)) {
        this.dimensionConverter(index);
      }
      const allNmfcRequiredFieldsFilled = this.validateFieldsToStandardizeNmfc(index);
      const fieldsAreSame = this.validateEqualFields(index);
      if (!fieldsAreSame && !notStandardizerValues.includes(key) && allNmfcRequiredFieldsFilled) {
        await this.standardizeNmfcClass(index);
        this.oldValueNmfcClass[index] = this.selectedLineItems[index]?.nmfc_class;
      }
      // Update in indexedDB only if is load update.
      if (loadId) {
        this.updateLineItemsInIndexedDB();
      }
      this.saveSelectedLineItemInVuex();
      if (this.oldValueNmfcClass.length > 0) {
        this.changeNmfc[index] = key === "nmfc_class"
          && this.oldValueNmfcClass[index] !== this.selectedLineItems[index]?.nmfc_class;
      }
    },
    validateFieldsToStandardizeNmfc(index) {
      const standardizerValues = [
        "handling_units",
        "handling_unit_count",
        "weight_units",
        "weight",
        "length",
        "height",
        "width",
        "dimension_units",
      ];
      const item = this.selectedLineItems[index];
      const allPropertiesHaveValue = standardizerValues.every((prop) => {
        let value = String(item[prop]);
        const numericFields = ["handling_unit_count", "weight", "length", "height", "width"];
        if (numericFields.includes(prop)) {
          return !Number.isNaN(value) && Number(value) > 0;
        }
        value = String(value);
        return value.trim() !== "" && value !== "NaN";
      });
      if (!allPropertiesHaveValue) {
        this.selectedLineItems[index].nmfc_class = null;
        this.initialNMFCList[index].nmfc_class = null;
        this.key += 1;
      }
      return allPropertiesHaveValue;
    },
    // validate if the vuex line item and selected line item are diferent to executed the standard.
    validateEqualFields(index) {
      // eslint-disable-next-line operator-linebreak
      const vuexLineItems = this.$deepCopy(
        this.$store.getters["load/lineItems/getSelectedLineItems"]
      );
      const keys = Object.keys(this.selectedLineItems[index]);
      let fieldsAreSame = true;
      keys.forEach((key) => {
        if (this.selectedLineItems[index][key] !== vuexLineItems[index][key]) {
          fieldsAreSame = false;
        }
        return null;
      });
      return fieldsAreSame;
    },
    async validateFormfields() {
      const lineItems = [...this.selectedLineItems];
      let validateForm = true;
      this.error = [];
      this.limitsLineItemsError = [];
      for (let i = 0; i < lineItems.length; i += 1) {
        try {
          // eslint-disable-next-line no-await-in-loop
          await LineItemSchema.validate(lineItems[i], {
            abortEarly: false,
          });
          const errorDetails = {
            description: false,
            handling_unit_count: false,
            height: false,
            length: false,
            nmfc_class: false,
            weight: false,
            width: false,
          };
          this.error.push(errorDetails);
        } catch (error) {
          validateForm = false;
          const errorDetails = {};
          error.inner.forEach((element) => {
            errorDetails[element.path] = true;
            // Aqui deberiamos de guardar en un array los errores de limites.
            if (
              element.type === "length-limit" ||
              element.type === "width-limit" ||
              element.type === "height-limit" ||
              element.type === "weight-limit"
            ) {
              const errrorMessage = element.message;
              this.limitsLineItemsError.push(errrorMessage);
            }
          });
          this.error.push(errorDetails);
        }
      }
      return validateForm;
    },
    returnLineItemsMessageError() {
      return this.limitsLineItemsError;
    },
    changeToNmfcCalculated(index) {
      this.selectedLineItems[index].nmfc_class = this.oldValueNmfcClass[index];
      this.changeNmfc[index] = false;
      this.key += 1;
    }
  },
};
</script>

<style lang="scss" scoped>
///****Import styles****\\\\
@import "../../../../assets/scss/components/LineItems.scss";
@import "../../../../assets/scss/components/RCComponents.scss";

::v-deep label {
  margin-bottom: 0px;
}

::v-deep .floatlabel-input {
  border: 1px solid $color-border-container;
}

.my-line-items-selector {
  width: 350px;
  margin-top: 30px;
}

.dropdown-container {
  // border: 1px solid $color-border-container;
  height: 42px;
}

.lineitems-spinner {
  width: 100%;
  text-align: center;
}

.lineitem-container {
  width: 100%;
  margin-bottom: 5px;
  &:not(:last-child) {
    border-bottom: 1px solid $color-border-container;
  }
  &__title {
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h6 {
      margin: 0px;
      margin-left: 6px;
    }
  }
}

.lineitem-row {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  margin: 20px 0px;
  position: relative;
}

.commodity-excluded {
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
  cursor: pointer;
  font-size: 12px;
  font-style: italic;
  font-family: "Montserrat";
  u {
    margin-left: 5px;
  }
}

.alert-changes-nmfc {
  cursor: pointer;
  color: $color-primary-company;
}

::v-deep {
  & .hucount {
    display: flex;
    flex-basis: 25%;
    &__dropdown {
      width: 65%;
      margin-left: 5px;
    }
    &__input {
      @include hide-input-number-arrows;
      width: 35%;
      height: 42px;
    }
  }
  & .pkgcount {
    display: flex;
    flex-basis: 16%;
    &__dropdown {
      width: 65%;
      margin-left: 5px;
    }
    &__input {
      @include hide-input-number-arrows;
      width: 35%;
      height: 42px;
    }
  }

  & .dimensions {
    @include remove-custom-component-styles;
    display: flex;
    flex-basis: 45%;
    list-style-type: none;
    &__input {
      &:not(:first-child) {
        & .rc-input-container,
        .rc-input-field {
          @include hide-input-number-arrows;
          border-radius: 0px;
          border-right: none;
        }
      }
      & .rc-input-container:nth-of-type(1),
      .rc-input-field:nth-of-type(1) {
        @include hide-input-number-arrows;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right: none;
      }
      width: 25%;
    }
    & .floatlabel-input {
      border: none;
    }
    &__dropdown {
      & .rc-select-container {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
      width: 25%;
    }
  }

  & .weight {
    @include remove-custom-component-styles;
    position: relative;
    display: flex;
    flex-basis: 20%;
    list-style-type: none;
    &__input {
      @include hide-input-number-arrows;
      width: 50%;
    }
    &__tooltip {
      position: absolute;
      top: -20px;
      right: 0px;
    }
    & .rc-input-container,
    .rc-input-field {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    &__dropdown {
      & .rc-select-container {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-left: none;
      }
      width: 50%;
    }
  }
  & .nmfc {
    width: 20%;
    &__dropdown {
      width: 100%;
      margin-right: 5px;
    }
    &__input {
      @include hide-input-number-arrows;
      width: 50%;
      height: 42px;
    }
  }
  & .commodity {
    flex-basis: 40%;
    &__input {
      // height: 42px;
    }
  }
}

.lineitem-inputs {
  margin: 0px 5px;
  height: 42px;
  & li {
    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
}

.input-container {
  border-radius: 10px !important;
}

.hazmat {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: -35px;
  margin-left: 7px;
}
///****Buttons styles****\\\\
.button-add {
  display: flex;
  align-items: center;
  color: $color-primary-company;
  border: none;
  padding: 0px;
  background: none;
}
.button-quote {
  border-radius: 15px;
  margin-right: 10px;
  padding: 0px 20px;
  border: none;
  background-color: $color-border-container;
  color: $color-white;
  height: 40px;
  font-weight: bold;
  font-family: $font-family-portal;
  @include font-small-standart-text;
  &:hover {
    background: $color-primary-trigger-button-hover;
  }
}
.input-container {
  @include input-information;
  margin: 0px;
  height: 40px;
  &:hover {
    box-shadow: none;
  }
  &:focus-within {
    box-shadow: none;
  }
}
.remove-button {
  right: 0%;
  top: 0%;
  @include go-back-button;
  padding: 0 10px 0 0;
  margin: 0;
  font-weight: bold;
  &__icon {
    font-size: 25px;
  }
}
.actions-button {
  display: flex;
  width: 5%;
  min-width: 25px;
  &__back-button {
    @include go-back-button;
    padding: 0 10px 0 0;
    margin: 0;
  }
  &__icon {
    @include go-back-button-icon;
    font-size: 25px;
    padding: 0;
    margin: 0;
  }
}
.input-information {
  @include input-password;
  width: 100%;
  @include font-small-standart-text;
  color: $color-primary-company;
  border-radius: 10px;
}
::placeholder {
  color: $color-gray-dark;
}
.button-hover {
  display: block;
}
.required-field {
  color: $color-error;
}
.disabled-content {
  pointer-events: none;
  .actions-button {
    pointer-events: all;
    display: block;
  }
  .remove-button {
    pointer-events: all;
  }
}

.disabled {
  pointer-events: none;
}
///****Responsive styles****\\\\
@media (max-width: 670px) {
  .actions-button {
    flex-direction: row;
    display: flex;
    width: 100%;
    .button-actions {
      width: 50%;
    }
    .button-delete {
      width: 50%;
    }
  }
  .my-line-items-selector {
    width: 100%;
    margin-top: 30px;
  }
}
::v-deep {
  .el-loading-mask {
    border-radius: 10px;
  }
}
</style>
